import { List, ListItem, ListItemButton, TablePagination } from '@mui/material'
import React, { FunctionComponent, useEffect } from 'react'
import { SetItemDto } from '../../../api/types/Sets/SetDto'
import SetsItemCard from './SetsItemCard'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { setCurrentItem, clearCurrentItem } from '../../../store/sets/currentItemSlice'
import { selectSetsList, loadSets, setRowsPerPage } from '../../../store/sets/setsListSlice'

interface Props {
  items: SetItemDto[];
}

const SetsList: FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const setsListState = useAppSelector(selectSetsList)

  useEffect(() => {
    dispatch(clearCurrentItem())
  }, [dispatch])

  const handleSetClick = (set: SetItemDto) => {
    dispatch(setCurrentItem(set))
    navigate(`/sets/${set.toolingSetNumber}`)
  }

  const handleChangePage = (page: number) => {
    dispatch(loadSets({ page: page, pageSize: setsListState.pageSize }))
  }

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    dispatch(setRowsPerPage(rowsPerPage))
    dispatch(loadSets({ page: 0, pageSize: rowsPerPage }))
  }

  return (
    <>
      <List disablePadding>
        {props.items.map((set) => {
          return <ListItem key={set.toolingSetId} disablePadding={true}>
            <ListItemButton onClick={() => handleSetClick(set)}>
              <SetsItemCard item={set}/>
            </ListItemButton>
          </ListItem>
        })}
      </List>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
        component="div"
        count={props.items.length}
        rowsPerPage={setsListState.pageSize}
        page={setsListState.page}
        onPageChange={(event, page) => handleChangePage(page)}
        onRowsPerPageChange={(event) => handleChangeRowsPerPage(parseInt(event.target.value ?? ''))}
      />
    </>
  )
}

export default SetsList
