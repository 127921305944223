import { Button, CircularProgress } from '@mui/material'
import { type Theme } from '@mui/material/styles/createTheme'
import { type SxProps } from '@mui/system'
import { type FC, useEffect, useState } from 'react'
import { useAppDispatch } from '../../../store/hooks'
import { exportSets } from '../../../store/sets/setsListSlice'
import { ButtonProps } from '@mui/material/Button/Button'

interface DownloadReportProps extends ButtonProps {
  name?: string
  sx?: SxProps<Theme>
}

export const DownloadReport: FC<DownloadReportProps> = (
  {
    disabled,
    name,
    sx,
  }) => {

  const [blob, setBlob] = useState<string | any>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (blob) {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.href = blob
      a.download = `${name}.xlsx`
      a.click()
      document.body.removeChild(a)
      setBlob(undefined)
    }
  }, [blob])

  const handleDownloadReport = async () => {
    setLoading(true)
    const blobStr = await dispatch(exportSets(
      {
        page: 0,
        pageSize: 10000,

      })).unwrap().catch(() => {
      setLoading(false)
    }).finally(() => {
      setLoading(false)
    })

    if (blobStr) {
      setBlob(blobStr)
    }
  }

  return (

    <Button
      variant="contained"
      disabled={loading || disabled}
      startIcon={
        loading
        ? (
          <CircularProgress size={16}/>
        )
        : (
          <></>
        )
      }
      onClick={handleDownloadReport}
      sx={{
        whiteSpace: 'nowrap',
        ...sx,
      }}
      fullWidth
    >
      {name}
    </Button>
  )
}
