import { createSlice, PayloadAction, SerializedError, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../store'
import unitsToolingApi from '../../api/UnitsToolingApi'
import unitsApi from '../../api/UnitsApi'
import { SearchUnit } from '../types/searchUnit'
import { SupplierDto } from '../../api/types/SupplierData'
import { ToolingUnitDto } from '../../api/types/UnitToolingDto'
import setsApi from '../../api/SetsApi'

export interface CreatNewSetDialogState {
  status: 'idle' | 'saving' | 'failed' | 'warning';
  activeStep: number
  question?: string;
  setSupplier: SupplierDto | null;
  setUnit?: SearchUnit;
  assemblyUnits?: SearchUnit []
  availableUnitsTooling?: ToolingUnitDto []
  selectedUnitsTooling: ToolingUnitDto []
  error?: SerializedError;
}

const initialState: CreatNewSetDialogState = {
  status: 'idle',
  setSupplier: null,
  activeStep: 0,
  selectedUnitsTooling: [],
}

export const getAssemblyUnitTooling = createAsyncThunk(
  'create-new-set-slice/getAssemblyUnitTooling',
  async (payload: { unitFileId: number }) => {
    return await unitsToolingApi.getAssemblyUnitToolings(payload.unitFileId)
  },
)

export const getAssemblyUnitBom = createAsyncThunk(
  'create-new-set-slice/getAssemblyUnitBom',
  async (payload: { unitFileId: number }) => {
    return await unitsApi.getUnitBom(payload.unitFileId)
  },
)

export const createSet = createAsyncThunk(
  'create-new-set-slice/createSet',
  async (payload: {
    toolingIds: number[],
    unitFileId: number,
    supplierId: number,
    description: string,
    comment: string
  }) => {
    return await setsApi.createSet({
                                     toolingIds: payload.toolingIds,
                                     unitFileId: payload.unitFileId,
                                     supplierId: payload.supplierId,
                                     description: payload.description,
                                     comment: payload.comment,
                                   })
  },
)

export const createNewSetDialogSlice =
               createSlice(
                 {
                   name: 'sets-create-new-set',
                   initialState,
                   reducers: {
                     clear: (state) => {
                       state.activeStep = 0
                       state.setUnit = undefined
                       state.setSupplier = null
                     },
                     clearUnits: (state) => {
                       state.setUnit = undefined
                     },
                     clearSupplier: (state) => {
                       state.setSupplier = null
                     },
                     clearToolingData: (state) => {
                       state.assemblyUnits = undefined
                       state.availableUnitsTooling = undefined
                       state.selectedUnitsTooling = []
                     },
                     setActiveStep: (
                       state, action: PayloadAction<number>) => {
                       state.activeStep = action.payload
                     },
                     setSelectedUnit: (
                       state, action: PayloadAction<SearchUnit | undefined>) => {
                       state.setUnit = action.payload
                     },
                     setSelectedSupplier: (
                       state, action: PayloadAction<SupplierDto | null>) => {
                       state.setSupplier = action.payload
                     },
                     processSelectedToolingUnit: (
                       state, action: PayloadAction<ToolingUnitDto>) => {
                       const isExist = state.selectedUnitsTooling
                                            .some(t => t.tooling.toolingNumber === action.payload.tooling.toolingNumber)
                       if (isExist) {
                         state.selectedUnitsTooling =
                           state.selectedUnitsTooling
                                .filter(t => t.tooling.toolingNumber !== action.payload.tooling.toolingNumber)
                       } else {
                         state.selectedUnitsTooling.push(action.payload)
                       }
                     },

                   },
                   extraReducers: (build) => {
                     build.addCase(getAssemblyUnitTooling.pending, (state) => {
                       state.status = 'saving'
                     })

                     build.addCase(getAssemblyUnitTooling.fulfilled, (
                       state, action) => {
                       state.status = 'idle'
                       state.availableUnitsTooling = action.payload
                     })

                     build.addCase(getAssemblyUnitTooling.rejected, (
                       state, action) => {
                       state.status = 'failed'
                       state.error = action.error
                     })
                     build.addCase(getAssemblyUnitBom.pending, (state) => {
                       state.status = 'saving'
                     })

                     build.addCase(getAssemblyUnitBom.fulfilled, (
                       state, action) => {
                       state.status = 'idle'
                       state.assemblyUnits = action.payload
                     })

                     build.addCase(getAssemblyUnitBom.rejected, (
                       state, action) => {
                       state.status = 'failed'
                       state.error = action.error
                     })

                     build.addCase(createSet.pending, (state) => {
                       state.status = 'saving'
                     })

                     build.addCase(createSet.fulfilled, (
                       state, action) => {
                     })

                     build.addCase(createSet.rejected, (
                       state, action) => {
                       state.status = 'failed'
                       state.error = action.error
                     })
                   },
                 })

export const {
               clear,
               setActiveStep,
               setSelectedUnit,
               setSelectedSupplier,
               processSelectedToolingUnit,
               clearSupplier,
               clearToolingData,
             } = createNewSetDialogSlice.actions

export const selectCreateNewSet = (state: RootState) => state.sets.createNewSet

export default createNewSetDialogSlice.reducer
