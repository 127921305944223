import { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Container, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import toolingApi from '../../../api/ToolingApi'
import { ToolingListResponse } from '../../../api/types/ToolingListResponse'
import CreateNewToolingDialog from '../components/CreateNewToolingDialog/CreateNewToolingDialog'
import SearchBox from '../../../components/SearchBox'
import { useAppSelector } from '../../../store/hooks'
import {
  selectToolingList,
  startLoading,
  successLoading,
  fail,
  startLoadingStatuses,
  successLoadingStatuses,
  failLoadingStatuses,
  startLoadingOwners,
  failLoadingOwners,
  successLoadingOwners,
  startExporting,
  setActionError,
  stopExporting,
  changePage,
  SortableHeads,
} from '../../../store/tooling/toolingListSlice'
import { useDispatch } from 'react-redux'
import ErrorBox from '../../../components/ErrorBox'
import ToolingTable from './components/ToolingTable'
import { ClearAll } from '@mui/icons-material'
import { StatusDto } from '../../../api/types/StatusDto'
import statusesApi from '../../../api/StatusesApi'
import StatusSelector from '../components/StatusSelector'
import OwnerSelector from '../components/OwnerSelector'
import { OwnerDto } from '../../../api/types/OwnerDto'
import ownersApi from '../../../api/OwnersApi'
import _ from 'lodash'
import { FileDownload } from '../../../components/FileDownload'
import ErrorDialog from '../../../components/ErrorDialog'
import {
  clearAllFilters,
  selectToolingListFilters,
  setOwnerFilter,
  setResponsibleFilter,
  setStatusFilter,
  setSupplierFilter,
  setToolingFilter,
  setUnitFilter,
} from '../../../store/tooling/toolingFiltersSlice'
import { OrderType } from '../../../helpers/tableCompare'

const ToolingListPage: FunctionComponent = () => {
  const [isOpenCreateNewDialog, setIsOpenCreateNewDialog] = useState(false)
  const [toolingList, setToolingList] = useState<ToolingListResponse | null>(null)

  const [statuses, setStatuses] = useState<StatusDto[]>([])
  const [owners, setOwners] = useState<OwnerDto[]>([])

  const toolingListState = useAppSelector(selectToolingList)
  const filtersState = useAppSelector(selectToolingListFilters)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const loadToolingList = (
    tooling?: string,
    status?: StatusDto,
    owner?: OwnerDto,
    supplier?: string,
    unit?: string,
    responsible?: string,
    page?: number,
    pageSize?: number,
    sortBy?: SortableHeads,
    sortDirection?: OrderType,
  ) => {
    dispatch(startLoading())
    toolingApi
      .getToolingList(tooling, status, owner, supplier, unit, responsible, page, pageSize, sortBy, sortDirection)
      .then((res) => {
        res && setToolingList(res)
        dispatch(successLoading())
      })
      .catch((e) => {
        setToolingList(null)
        dispatch(fail(e))
      })
  }

  const loadToolingListDefault = () => {
    loadToolingListThrottled(
      filtersState.toolingFilter,
      filtersState.statusFilter,
      filtersState.ownerFilter,
      filtersState.supplierFilter,
      filtersState.unitFilter,
      filtersState.responsibleFilter,
      toolingListState.page,
      toolingListState.pageSize,
      toolingListState.sortBy,
      toolingListState.sortDirection,
    )
  }

  const loadToolingListThrottled = useCallback(
    _.debounce(
      (
        toolingFilter?: string,
        status?: StatusDto,
        owner?: OwnerDto,
        supplier?: string,
        unit?: string,
        responsible?: string,
        page?: number,
        pageSize?: number,
        sortBy?: SortableHeads,
        sortDirection?: OrderType,
      ) =>
        loadToolingList(
          toolingFilter,
          status,
          owner,
          supplier,
          unit,
          responsible,
          page,
          pageSize,
          sortBy,
          sortDirection,
        ),
      600,
    ),
    [],
  )

  useEffect(() => {
    dispatch(changePage(0))
  }, [
              filtersState.toolingFilter,
              filtersState.statusFilter,
              filtersState.ownerFilter,
              filtersState.supplierFilter,
              filtersState.unitFilter,
              filtersState.responsibleFilter,
              dispatch,
            ])

  useEffect(() => {
    loadToolingListThrottled(
      filtersState.toolingFilter,
      filtersState.statusFilter,
      filtersState.ownerFilter,
      filtersState.supplierFilter,
      filtersState.unitFilter,
      filtersState.responsibleFilter,
      toolingListState.page,
      toolingListState.pageSize,
      toolingListState.sortBy,
      toolingListState.sortDirection,
    )
  }, [
              loadToolingListThrottled,
              filtersState.toolingFilter,
              filtersState.statusFilter,
              filtersState.ownerFilter,
              filtersState.supplierFilter,
              filtersState.unitFilter,
              filtersState.responsibleFilter,
              toolingListState.page,
              toolingListState.pageSize,
              toolingListState.sortBy,
              toolingListState.sortDirection,
            ])

  useEffect(() => {
    dispatch(startLoadingStatuses())

    statusesApi
      .getStatuses()
      .then((res) => {
        res && setStatuses(res)
        dispatch(successLoadingStatuses())
      })
      .catch((e) => {
        dispatch(failLoadingStatuses(e))
      })

    dispatch(startLoadingOwners())

    ownersApi
      .getOwners()
      .then((res) => {
        res && setOwners(res)
        dispatch(successLoadingOwners())
      })
      .catch((e) => {
        dispatch(failLoadingOwners(e))
      })
  }, [dispatch])

  const handleCreateNew = () => {
    setIsOpenCreateNewDialog(true)
  }

  const handleExport = () => {
    dispatch(startExporting())
  }

  const handleExportError = (error: Error) => {
    dispatch(stopExporting())
    dispatch(setActionError(error))
  }

  const handleEndExport = () => {
    dispatch(stopExporting())
  }

  const handleChangeStatusFilter = (status?: StatusDto) => {
    dispatch(setStatusFilter(status))
  }

  const handleChangeOwnerFilter = (owner?: OwnerDto) => {
    dispatch(setOwnerFilter(owner))
  }

  const handleClickClearAll = () => {
    dispatch(clearAllFilters())
  }

  if (toolingListState.status === 'failed') {
    if (toolingListState.error) {
      return (
        <ErrorBox
          code={toolingListState.error.name}
          description={toolingListState.error.message}
          sx={{ minHeight: '90vh' }}
        >
          <Button variant="contained" onClick={() => loadToolingListDefault()}>
            Try again
          </Button>
        </ErrorBox>
      )
    } else {
      return (
        <ErrorBox code="500" description="Error while loading data" sx={{ minHeight: '90vh' }}>
          <Button variant="contained" onClick={() => loadToolingListDefault()}>
            Try again
          </Button>
        </ErrorBox>
      )
    }
  }

  return (
    <Fragment>
      <Container maxWidth="xl" sx={{ boxShadow: 'none' }}>
        <Box mt={4} mb={4}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs>
              <Typography variant="h4">Tooling</Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleCreateNew}>
                Create new
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                disabled={toolingListState.isExporting}
                startIcon={
                  toolingListState.isExporting ? <CircularProgress size={25} variant="indeterminate"/> : <Fragment/>
                }
                onClick={handleExport}
              >
                Export filtered
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h6">Filters</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} md={12} lg={4} xl={2}>
                <SearchBox
                  caption="Tooling number"
                  size="small"
                  fullWidth
                  filter={filtersState.toolingFilter}
                  onFilterChanged={(filter) => dispatch(setToolingFilter(filter))}
                  onFilter={() => {
                  }}
                  onClear={() => dispatch(setToolingFilter(''))}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={2}>
                {toolingListState.statusesStatus !== 'failed' && (
                  <StatusSelector
                    isLoading={toolingListState.statusesStatus === 'loading'}
                    size="small"
                    statuses={statuses}
                    hasEmpty={true}
                    currentStatus={filtersState.statusFilter?.statusId}
                    onChange={handleChangeStatusFilter}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={2}>
                {toolingListState.ownersStatus !== 'failed' && (
                  <OwnerSelector
                    isLoading={toolingListState.ownersStatus === 'loading'}
                    size="small"
                    owners={owners}
                    selectedOwner={filtersState.ownerFilter}
                    onChangeOwner={handleChangeOwnerFilter}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg>
                <SearchBox
                  caption="Supplier"
                  size="small"
                  fullWidth
                  filter={filtersState.supplierFilter}
                  onFilterChanged={(filter) => dispatch(setSupplierFilter(filter))}
                  onFilter={() => {
                  }}
                  onClear={() => dispatch(setSupplierFilter(''))}
                />
              </Grid>
              <Grid item xs={12} md={6} lg>
                <SearchBox
                  caption="Unit"
                  size="small"
                  fullWidth
                  filter={filtersState.unitFilter}
                  onFilterChanged={(filter) => dispatch(setUnitFilter(filter))}
                  onFilter={() => {
                  }}
                  onClear={() => dispatch(setUnitFilter(''))}
                />
              </Grid>
              <Grid item xs={12} md={6} lg>
                <SearchBox
                  caption="Responsbile"
                  size="small"
                  fullWidth
                  filter={filtersState.responsibleFilter}
                  onFilterChanged={(filter) => dispatch(setResponsibleFilter(filter))}
                  onFilter={() => {
                  }}
                  onClear={() => dispatch(setResponsibleFilter(''))}
                />
              </Grid>
              <Grid item xs="auto">
                <Tooltip title="Clear all">
                  <IconButton onClick={handleClickClearAll}>
                    <ClearAll/>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box mt={4}>
          <ToolingTable status={toolingListState.status} items={toolingList}/>
        </Box>
      </Container>

      <CreateNewToolingDialog
        open={isOpenCreateNewDialog}
        onClose={(tooling) => {
          if (tooling) {
            navigate(`/tooling/${tooling.toolingId}`)
          } else {
            setIsOpenCreateNewDialog(false)
          }
        }}
      />

      {toolingListState.isExporting && (
        <FileDownload
          url={toolingApi.getExportUrl(
            filtersState.toolingFilter,
            filtersState.statusFilter,
            filtersState.ownerFilter,
            filtersState.supplierFilter,
            filtersState.unitFilter,
            filtersState.responsibleFilter,
            toolingListState.sortBy,
            toolingListState.sortDirection,
          )}
          filename={'Tooling.xlsx'}
          onError={handleExportError}
          onDownloaded={handleEndExport}
        />
      )}

      <ErrorDialog
        open={toolingListState.actionError !== undefined}
        title={toolingListState.actionError?.name || 'Error'}
        text={`Error while saving data. Please try again later or contact the site administrator.\n${toolingListState.actionError?.message}`}
        onOk={() => dispatch(setActionError(undefined))}
      />
    </Fragment>
  )
}

export default ToolingListPage
