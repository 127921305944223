import React, { Fragment, FunctionComponent } from 'react'
import { SearchUnit } from '../../../store/types/searchUnit'
import { ToolingUnitDto } from '../../../api/types/UnitToolingDto'
import { Grid, Typography, Stack, List, listItemClasses, ListItem, Checkbox } from '@mui/material'
import { UnitElementId, UnitProductionId } from '../../tooling/components/UnitIndicator'
import { useAppDispatch } from '../../../store/hooks'
import { processSelectedToolingUnit } from '../../../store/sets/createNewSetDialogSlice'

interface Props {
  assemblyUnits?: SearchUnit []
  availableUnitsTooling?: ToolingUnitDto []
  selectedUnitsTooling: ToolingUnitDto []
}

const checkUnitToolingExist = (unitFileId: number, unitsTooling: ToolingUnitDto []): boolean => {
  return unitsTooling
    .filter(t => t.unitFileId === unitFileId).length > 0
}

const getUnitTooling = (unitFileId: number, unitsTooling: ToolingUnitDto []): ToolingUnitDto [] => {
  return unitsTooling
    .filter(t => t.unitFileId === unitFileId)
}

const checkUnitToolingSelected = (unitTooling: ToolingUnitDto, unitsTooling: ToolingUnitDto []): boolean => {
  const isExistUnit = unitsTooling.some(t => t.unitFileId === unitTooling.unitFileId)
  const isCurrentSelected = unitsTooling.some(t => t.tooling.toolingNumber === unitTooling.tooling.toolingNumber)
  if (isCurrentSelected) {
    return false
  }
  return isExistUnit

}

const SetUnitsTooling: FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch()

  const handleCheck = (toolingUnit: ToolingUnitDto) => () => {
    dispatch(processSelectedToolingUnit(toolingUnit))
  }
  return (
    <Fragment>
      <Grid container direction="column" spacing={4}>
        {props.assemblyUnits
          && props.assemblyUnits
                  .map((unit) =>
                         <Grid item>
                           <Typography
                             sx={{ fontWeight: 600 }}>{unit.elementName}</Typography>
                           <Stack direction="row" spacing={3} alignItems="center">
                             <UnitElementId elementId={unit.elementId}/>
                             <UnitProductionId
                               productionId={unit.productionId}/>
                           </Stack>
                           <List
                             sx={{
                               [`& .${listItemClasses.root}:hover`]: {
                                 backgroundColor: (theme) => theme.palette.action.hover,
                               },
                             }}
                           >
                             {props.availableUnitsTooling
                              && checkUnitToolingExist(unit.unitFileId, props.availableUnitsTooling) ?
                              getUnitTooling(unit.unitFileId, props.availableUnitsTooling)
                                .map((t) => {
                                  const labelId = `tooling-list-label-${t.tooling.toolingNumber}`
                                  return (
                                    <ListItem
                                      key={t.tooling.toolingNumber}
                                      divider
                                      secondaryAction={
                                        <Checkbox
                                          edge="end"
                                          onChange={handleCheck(t)}
                                          inputProps={{ 'aria-labelledby': labelId }}
                                          checked={props.selectedUnitsTooling.includes(t)}
                                          disabled={checkUnitToolingSelected(t, props.selectedUnitsTooling)}
                                        />
                                      }
                                    >
                                      <Stack spacing={1}>
                                        <Typography sx={{ fontWeight: 600 }}>{t.tooling.toolingNumber}</Typography>
                                      </Stack>
                                    </ListItem>
                                  )
                                }) :
                              <ListItem
                                key={unit.unitFileId}
                                divider
                              >
                                <Stack spacing={1}>
                                  <Typography sx={{ fontWeight: 600 }}>No tooling</Typography>
                                </Stack>
                              </ListItem>
                             }
                           </List>
                         </Grid>)}

      </Grid>
    </Fragment>
  )
}

export default SetUnitsTooling
