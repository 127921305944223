import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import toolingApi from '../../../api/ToolingApi';
import { OwnerDto } from '../../../api/types/OwnerDto';
import { SupplierDto } from '../../../api/types/SupplierData';
import { ToolingListItemDto } from '../../../api/types/ToolingDto';
import { RootState } from '../../store';
import { SearchUnit } from '../../types/searchUnit';

export interface ToolingCreateState {
  allowableNumberOfUsages?: number;
  status: 'idle' | 'saving' | 'failed' | 'warning';
  question?: string;
  error?: SerializedError;
}

const initialState: ToolingCreateState = {
  status: 'idle',
};

export const saveTooling = createAsyncThunk(
  'tooling-create-dialog-slice/save',
  async (data: {
    allowableNumberOfUsages: number | undefined;
    selectedSuppplier: SupplierDto | null;
    selectedUnits: SearchUnit[];
    selectedOwner: OwnerDto | undefined;
    materialId: number | undefined;
  }) => {
    if (data.allowableNumberOfUsages === undefined || data.allowableNumberOfUsages <= 0) {
      return undefined;
    }

    if (!data.selectedSuppplier) {
      return undefined;
    }

    return await toolingApi.create(
      data.selectedUnits,
      data.allowableNumberOfUsages,
      data.selectedOwner,
      data.selectedSuppplier,
      data.materialId,
    );
  },
);

export const dialogSlice = createSlice({
  name: 'tooling-create-dialog-slice',
  initialState,
  reducers: {
    setAllowableNumbersOfUsages: (state, action: PayloadAction<number | undefined>) => {
      state.allowableNumberOfUsages = action.payload;
    },
    setError: (state, action: PayloadAction<SerializedError>) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    setIdleStatus: (state) => {
      state.status = 'idle';
    },
    setSavingStatus: (state) => {
      state.status = 'saving';
    },
    setQuestion: (state, action: PayloadAction<string>) => {
      state.status = 'warning';
      state.question = action.payload;
    },
  },
  extraReducers: (build) => {
    build.addCase(saveTooling.pending, (state, action) => {
      state.status = 'saving';
    });

    build.addCase(saveTooling.fulfilled, (state) => {
      state.status = 'idle';
    });

    build.addCase(saveTooling.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error;
    });
  },
});

export const { setAllowableNumbersOfUsages, setError, setIdleStatus, setSavingStatus, setQuestion } =
  dialogSlice.actions;

export const selectToolingCreateDialog = (state: RootState) => state.tooling.create.dialog;

export default dialogSlice.reducer;
