import React, { FunctionComponent, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  Link,
  Skeleton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { Edit } from '@mui/icons-material'
import PropertyItem from './PropertyItem'
import { ToolingViewState, selectToolingView, setEditComment } from '../../../store/tooling/toolingViewSlice'
import EditSummaryDialog from './components/EditSummaryDialog'
import LoadingTypography from './components/LoadingTypography'
import { useAppDispatch, useAppSelector, useAccount } from '../../../store/hooks'
import Cost from './components/Cost'
import CommentEditor from './components/CommentEditor'
import { saveComment } from '../../../store/tooling/comments'
import { useCheckResponsibleOrPermissions } from '../../../helpers/useCheckResponsibleOrPermissions'
import {
  Permissions_Tooling_Comment_Edit, Permissions_Tooling_Summary_Edit,
} from '../../../api/types/permissions'
import { checkPermission } from '../../../store/user/userSlice'
import { SCRAPPED_STATUS } from '../../../store/types/statuses'

interface Props {
  toolingId: string | number;
}

const SummaryCard: FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch()

  const [editSummary, setEditSummary] = useState(false)

  const toolingViewState: ToolingViewState = useAppSelector(selectToolingView)
  const isLoading = toolingViewState.status === 'loading'

  const locationName = toolingViewState.tooling?.summary?.location?.atSupplier
                       ? toolingViewState.tooling.summary.location.atSupplier.name
                       : toolingViewState.tooling?.summary?.location?.atStock?.name

  const commentPermissions = useCheckResponsibleOrPermissions(
    toolingViewState.tooling?.responsibles?.map((u) => u.userId) ?? [],
    Permissions_Tooling_Comment_Edit,
  )

  const haveEditSummaryRight = useAppSelector((root) => checkPermission(root, Permissions_Tooling_Summary_Edit))
  const userAccount = useAccount()

  const calcEditPermissions = () => {
    const responsibles = toolingViewState.tooling?.responsibles.filter((r) => r.userId === userAccount.localAccountId)

    const isScrappedStatus = toolingViewState.tooling?.summary?.status?.name === SCRAPPED_STATUS

    if (isScrappedStatus) {
      return (): boolean => false
    }

    return (): boolean => {
      if (haveEditSummaryRight) {
        return true
      } else {
        if (responsibles) {
          return responsibles.length > 0
        } else {
          return false
        }
      }
    }
  }

  const canEditSummary = calcEditPermissions()

  const handleClickEditComment = () => {
    dispatch(setEditComment(true))
  }

  const handleSaveComment = (comment: string) => {
    dispatch(saveComment({ toolingId: props.toolingId, comment }))
  }

  const handleCancelEditComment = () => {
    dispatch(setEditComment(false))
  }

  return (
    <Card>
      <CardHeader
        title="Summary"
        titleTypographyProps={{
          variant: 'textLgSemibold',
        }}
        action={
          toolingViewState.status === 'idle' &&
          toolingViewState.tooling?.technicalInfo &&
          canEditSummary() && (
            <Toolbar>
              <Tooltip title="Edit summary information">
                <IconButton onClick={() => setEditSummary(true)}>
                  <Edit/>
                </IconButton>
              </Tooltip>
            </Toolbar>
          )
        }
      />
      <Divider/>
      <CardContent>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} sm={6} container direction="column" spacing={1}>
            <PropertyItem caption="Tooling number">
              <LoadingTypography isLoading={isLoading} loadingWidth={100}>
                {toolingViewState.tooling?.summary?.toolingNumber}
              </LoadingTypography>
            </PropertyItem>
            <PropertyItem caption="Description">
              <LoadingTypography isLoading={isLoading}>
                {toolingViewState.tooling?.summary?.description}
              </LoadingTypography>
            </PropertyItem>
            <PropertyItem caption="Marking number">
              <LoadingTypography isLoading={isLoading}>
                {toolingViewState.tooling?.summary?.markingNumber}
              </LoadingTypography>
            </PropertyItem>
            <PropertyItem caption="Owner">
              <LoadingTypography isLoading={isLoading} loadingWidth={50}>
                {toolingViewState.tooling?.summary?.owner?.name}
              </LoadingTypography>
            </PropertyItem>

            <PropertyItem caption="Location">
              <Stack spacing={1} direction="row" alignItems="center">
                {!isLoading &&
                  (toolingViewState.tooling?.summary?.location?.atSupplier ||
                    toolingViewState.tooling?.summary?.location?.atStock) && (
                    <Chip label={toolingViewState.tooling?.summary?.location?.atSupplier ? 'Supplier' : 'Stock'}/>
                  )}
                <LoadingTypography isLoading={isLoading} loadingWidth={200}>
                  {locationName ?? ''}
                </LoadingTypography>
              </Stack>
            </PropertyItem>
            <PropertyItem caption="Material">
              <LoadingTypography isLoading={isLoading} loadingWidth={200}>
                {toolingViewState.tooling?.summary?.material?.name}
              </LoadingTypography>
            </PropertyItem>
          </Grid>
          <Grid item xs container direction="column" spacing={1}>
            <PropertyItem caption="Purchase order">
              <LoadingTypography isLoading={isLoading} loadingWidth={200}>
                {toolingViewState.tooling?.summary?.purchaseOrderTitle}
              </LoadingTypography>
            </PropertyItem>
            <PropertyItem caption="Purchase supplier">
              <LoadingTypography isLoading={isLoading} loadingWidth={200}>
                {toolingViewState.tooling?.summary?.purchaseSupplier?.name}
              </LoadingTypography>
            </PropertyItem>
            <PropertyItem caption="Purchase date">
              <LoadingTypography isLoading={isLoading} loadingWidth={100}>
                {toolingViewState.tooling?.summary?.purchaseDate
                 ? new Date(toolingViewState.tooling?.summary?.purchaseDate).toLocaleDateString()
                 : ''}
              </LoadingTypography>
            </PropertyItem>
            <PropertyItem caption="Cost (VAT included)">
              {isLoading ? (
                <Skeleton width={100}/>
              ) : (
                 <Cost
                   amount={toolingViewState.tooling?.summary?.cost}
                   currency={toolingViewState.tooling?.summary?.currency?.name}
                 />
               )}
            </PropertyItem>
            <PropertyItem caption="Actual number of usages">
              <LoadingTypography isLoading={isLoading} loadingWidth={200}>
                {toolingViewState.tooling?.summary?.actualNumberOfUsages}
              </LoadingTypography>
            </PropertyItem>
            <PropertyItem caption="Allowable number of usages">
              <LoadingTypography isLoading={isLoading} loadingWidth={200}>
                {toolingViewState.tooling?.summary?.allowableNumberOfUsages}
              </LoadingTypography>
            </PropertyItem>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 6 }}/>
        <Grid container>
          <Grid item container alignItems="left" direction="row" sx={{ mt: 6 }}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="textSmMedium">Comment</Typography>
                {commentPermissions && !toolingViewState.editComment && !isLoading && (
                  <Link component="button" variant="textSmRegular" onClick={handleClickEditComment}>
                    Edit
                  </Link>
                )}
              </Stack>
            </Grid>
            {toolingViewState.editComment && commentPermissions && (
              <CommentEditor
                initialValue={toolingViewState.tooling?.comment ?? ''}
                disabled={toolingViewState.saveComment}
                onSave={handleSaveComment}
                onCancel={handleCancelEditComment}
              />
            )}
            <Grid item xs={12}>
              {isLoading ? (
                <Skeleton width={500}/>
              ) : (
                 <Box>
                   {(toolingViewState.tooling?.comment ?? '').split('\n').map((p, key) => (
                     <Typography key={key} component="p" variant="textSmRegular">
                       {p}
                     </Typography>
                   ))}
                 </Box>
               )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <EditSummaryDialog
        open={editSummary}
        toolingId={props.toolingId}
        summary={toolingViewState.tooling?.summary}
        suppliers={toolingViewState.tooling?.suppliers}
        onClose={() => setEditSummary(false)}
      />
    </Card>
  )
}

export default SummaryCard
